.container-slider {
  position: relative;
  padding-top: 56%;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
@media screen and (max-width: 700px){
  .container-slider {
    
  }
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;


}
.slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.active-anim {
  animation: fadeInAnimation ease .5s;
            animation-iteration-count: 1;
            animation-fill-mode: forwards;

}
@keyframes fadeInAnimation {
  0% {
      opacity: 0.5;
  }
  100% {
      opacity: 1;
  }
}

.btn-slide {
  width: 60px;
  height: 100%;
  background: transparent;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  opacity: .5;
  transition: opacity .2s ease;
}
.btn-slide:hover {
  opacity: .9;
}

.btn-slide img {
  width: 40px;
  height: 40px;
  pointer-events: none;
}
.prev {
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}
.next {
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}
@media screen and (max-width: 900px){
  .prev {
    left: 20px;
  }
  .next {
    right: 20px;
  }
}


.container-dots {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  display: flex;

}
.dot {
  width: 30px;
  height: 3px;
  opacity: .5;
  transition: opacity .2s ease;
  border: 2px solid #000;
  margin: 0 5px;
  background: #fff;


  border: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dot.active {
  opacity: 1;

}
.swiper-pointer-events {
  max-width: 800px;
}
.swiper-pagination {
  bottom: 0;
  margin-top: 20px;


}
.swiper-button-prev, .swiper-button-next {
  transform: translateY(-50%);
}

.txt-container {
  display: none;
  position: absolute;
  bottom: 40px;
  margin-bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  transition: display .2s ease;
  text-align: center;
}
.txt-container.active {
  display: block;
}