@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    body {
        @apply h-full leading-[1.6] text-[15px] md:text-[10px] text-black bg-white lg:text-[14px]
    }
    h2 {
        @apply font-bold text-[30px] md:text-[40px]
    }
    h3 {
        @apply font-bold text-[22px] md:text-[30px] 
    }
    h5 {
        @apply font-bold text-[18px] md:text-[20px]
    }
    h6 {
        @apply font-bold text-[14px] md:text-[16px]
    }
    p {
        @apply mb-[20px] text-[14px]
    }
    blockquote {
        @apply text-[17.5px] italic
    }

}
@layer components {
    .text-input {
        @apply mt-2 block w-full rounded border-2 border-gray-300 focus:border-yellow-400 focus:ring-0;
      }
    .showcase-dropdown {
        @apply text-[12px] md:text-[16px] md:leading-[18px] 
    }
    .click-effect {
        @apply before:content-[""] before:bottom-0 before:left-0 before:pointer-events-none before:absolute before:right-0 before:top-0 before
    }
    .heading {
        @apply text-center mb-[50px]
    }
    .shadow-template {
        @apply shadow-[0_3px_8px_rgba(0,0,0,.24)]
    }
    .section-padding {
        @apply py-[80px]
    }
    .section-container {
        @apply max-w-[1440px] mx-auto px-[25px] sm:px-6
        
    }
    .md-section-container {
        @apply max-w-[1240px] mx-auto px-[25px] sm:px-6
    }
    .inner-container {
        @apply max-w-[1000px] mx-auto
    }
    .text-small {
        @apply text-[12px] leading-[18px]
    }
    .cta {
        @apply text-[20px] rounded-[30px] bg-[#c10841] text-[#fff] px-[45px] py-[16px] transition-all ease-linear block w-fit mx-auto mt-[50px] hover:bg-[rgba(193,8,65,.8)]
    }
    .home-cta {
        @apply text-sm md:text-base rounded-[30px] bg-black text-[#fff]  p-8 py-3 transition-all ease-linear block w-fit mx-auto mt-12 whitespace-nowrap hover:bg-[rgba(193,8,65,.8)]
    }
    
    .visible {
        visibility: visible !important;
    }
    .modal-btn {
        @apply rounded-3xl text-base border-2 px-8 py-[6px] block mx-auto font-bold transition-all duration-100
    }
    .swiper {
      @apply md:hidden block
    }
    
}

.checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #4ade80;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }
  
  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #4ade80;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }
  
  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }
  
  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }
  
  @keyframes scale {
    0%,
    100% {
      transform: none;
    }
  
    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }
  
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #4ade80;
    }
  }

  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .Mui-disabled {
    background-color: hsl(0, 0%, 95%) !important;
    
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: hsl(0, 0%, 90%) !important;
  }

  
  